import { BrowserRouter as Router, Route } from "react-router-dom";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { AnimatedSwitch } from "react-router-transition";
import FetchData from "./FetchData";
import AppTitle from "../components/AppTitle";
import { useTranslation } from "react-i18next";
import LoadingLogo from "../components/LoadingLogo";
import ScrollToTop from "../components/ScrollToTop";
import TrackPageView from "../components/TrackPageView";
import { Toaster } from "react-hot-toast";
import MyModal from "../components/Modal";
import isMobile from "ismobilejs";

const TripPdf = lazy(() => import("../pages/TripPdf"));
const Compass = lazy(() => import("../pages/Compass"));
const Deals = lazy(() => import("../pages/Deals"));
const Plan = lazy(() => import("../pages/Plan"));
const Search = lazy(() => import("../pages/Search"));
const POI = lazy(() => import("../pages/POI"));
const Favorite = lazy(() => import("../pages/Favorite"));
const Experience = lazy(() => import("../pages/Experience"));
const Circuit = lazy(() => import("../pages/Circuit"));
const City = lazy(() => import("../pages/City"));
const Tag = lazy(() => import("../pages/Tag"));
const Deal = lazy(() => import("../pages/Deal"));
const Contact = lazy(() => import("../pages/Contact"));
const SpecialPage = lazy(() => import("../pages/SpecialPage"));
const Sports = lazy(() => import("../pages/Sports"));
const InfoPage = lazy(() => import("../pages/InfoPage"));
const WhoAreWe = lazy(() => import("../pages/WhoAreWe"));
const Cooperative = lazy(() => import("../pages/Cooperative"));
const DocTourist = lazy(() => import("../pages/DocTourist"));
const News = lazy(() => import("../pages/News"));
const NewsInfo = lazy(() => import("../pages/NewsInfo"));
const Tifinagh = lazy(() => import("../pages/Tifinagh"));
// import privacy page
const PrivacyPage = lazy(() => import("../pages/Privacy"));
const MobilePrivacy = lazy(() => import("../pages/MobilePrivacy"));

//delay explore until animation done
const Explore = lazy(() => {
  return Promise.all([
    import("../pages/Explore"),
    new Promise((resolve) => setTimeout(resolve, 1250)),
  ]).then(([moduleExports]) => moduleExports);
});

export default function App() {
  let { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseMobileModal = () => {
    setShowMobileModal(false);
  };

  useEffect(() => {
    const hasModalBeenShown = localStorage.getItem("modalShown");

    if (!hasModalBeenShown) {
      const mouseOutHandler = (e) => {
        const shouldShowExitIntent =
          !e.toElement && !e.relatedTarget && e.clientY < 10;

        if (shouldShowExitIntent && !showModal) {
          setShowModal(true);
          localStorage.setItem("modalShown", "true");
        }
      };

      document.addEventListener("mouseout", mouseOutHandler);

      return () => {
        document.removeEventListener("mouseout", mouseOutHandler);
      };
    }
  }, [showModal]);

  useEffect(() => {
    // make setShowMobileModal after 60sec
    setTimeout(() => {
      setShowMobileModal(true);
    }, 1000);
  }, []);

  return (
    <>
      <Router>
        {isMobile().phone ? (
          <MyModal onClose={handleCloseMobileModal} show={showMobileModal} />
        ) : (
          <MyModal onClose={handleCloseModal} show={showModal} />
        )}
        <ScrollToTop />
        <TrackPageView />
        <Toaster />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route key="privacy" exact path="/:locale/appprivacy">
            <Suspense fallback={<LoadingLogo />}>
              <MobilePrivacy />
            </Suspense>
          </Route>
          <Route key="privacy" exact path="/:locale/privacy">
            <Suspense fallback={<LoadingLogo />}>
              <PrivacyPage />
            </Suspense>
          </Route>
          <Route
            key="POI"
            exact
            path="/:locale/tifinagh"
            render={(props) => (
              <Suspense fallback={<LoadingLogo />}>
                <Tifinagh {...props} />
              </Suspense>
            )}
          />
          <Route
            key="compass"
            exact
            path="/:locale/mapfree"
            render={(props) => (
              <Suspense fallback={<LoadingLogo />}>
                <Compass {...props} />
              </Suspense>
            )}
          />

          <Route
            key="pdf"
            exact
            path="/:locale/pdf"
            render={(props) => (
              <Suspense fallback={<LoadingLogo />}>
                <TripPdf {...props} />
              </Suspense>
            )}
          />

          <Route key="POI" exact path="/:locale/poi/:id">
            <Suspense fallback={<LoadingLogo />}>
              <POI />
            </Suspense>
          </Route>
          <Route key="Deal" exact path="/:locale/deal/:id">
            <Suspense fallback={<LoadingLogo />}>
              <Deal />
            </Suspense>
          </Route>
          <Route
            exact
            path="/:locale/:cat/:slug/:id"
            render={(props) => (
              <Suspense fallback={<LoadingLogo />}>
                <POI route={props} />
              </Suspense>
            )}
          />

          <Route
            key="plan"
            exact
            path="/:locale/plan/:id?"
            render={(props) => (
              <Suspense fallback={<LoadingLogo />}>
                <Plan route={props} />
              </Suspense>
            )}
          ></Route>
          <Route key="experience" exact path="/:locale/experience/:id">
            <Suspense fallback={<LoadingLogo />}>
              <Experience />
            </Suspense>
            /
          </Route>
          <Route key="favorite" exact path="/:locale/favorite">
            <Suspense fallback={<LoadingLogo />}>
              <Favorite />
            </Suspense>
          </Route>
          <Route key="deals" exact path="/:locale/deals">
            <Suspense fallback={<LoadingLogo />}>
              <Deals />
            </Suspense>
          </Route>
          <Route key="cooperative" exact path="/:locale/cooperative">
            <Suspense fallback={<LoadingLogo />}>
              <Cooperative />
            </Suspense>
          </Route>
          <Route key="search" exact path="/:locale/search">
            <Suspense fallback={<LoadingLogo />}>
              <Search />
            </Suspense>
          </Route>
          <Route key="info" exact path="/:locale/info">
            <Suspense fallback={<LoadingLogo />}>
              <InfoPage />
            </Suspense>
          </Route>
          <Route key="whoarewe" exact path="/:locale/whoarewe">
            <Suspense fallback={<LoadingLogo />}>
              <WhoAreWe />
            </Suspense>
          </Route>
          <Route key="doctourist" exact path="/:locale/doctourist">
            <Suspense fallback={<LoadingLogo />}>
              <DocTourist />
            </Suspense>
          </Route>
          {/* <Route key="doctourist" exact path="/:locale/newsinfo/:id">
            <Suspense fallback={<LoadingLogo />}>
              <NewsInfo />
            </Suspense>
          </Route> */}
          <Route key="contact" exact path="/:locale/contact">
            <Suspense fallback={<LoadingLogo />}>
              <Contact />
            </Suspense>
          </Route>
          <Route key="restaurants" exact path="/:locale/restaurants">
            <Suspense fallback={<LoadingLogo />}>
              <SpecialPage slug="restaurant" tag="gastronomie" />
            </Suspense>
          </Route>
          <Route key="agencesdevoyage" exact path="/:locale/agencesdevoyage">
            <Suspense fallback={<LoadingLogo />}>
              <SpecialPage slug="travelAgencies" tag="agence-de-voyages" />
            </Suspense>
          </Route>
          <Route key="sports" exact path="/:locale/sports/:id">
            <Suspense fallback={<LoadingLogo />}>
              <Sports />
            </Suspense>
          </Route>
          <Route key="event" exact path="/:locale/event/:id">
            <Suspense fallback={<LoadingLogo />}>
              <Sports />
            </Suspense>
          </Route>

          <Route key="sports" exact path="/:locale/circuit/:id">
            <Suspense fallback={<LoadingLogo />}>
              <Circuit />
            </Suspense>
          </Route>
          <Route key="hotels" exact path="/:locale/hotels">
            <Suspense fallback={<LoadingLogo />}>
              <SpecialPage slug="hotels" tag="hebergement" />
            </Suspense>
          </Route>
          <Route key="city" exact path="/:locale/city/:id">
            <Suspense fallback={<LoadingLogo />}>
              <City />
            </Suspense>
          </Route>

          <Route key="tag" exact path="/:locale/explore/:slug">
            <Suspense fallback={<LoadingLogo />}>
              <Tag />
            </Suspense>
          </Route>
          <Route key="explore" exact path="/:locale">
            <Suspense fallback={<LoadingLogo />}>
              <Explore />
            </Suspense>
          </Route>
          {/* <Route key="news" exact path="/:locale/news">
            <Suspense fallback={<LoadingLogo />}>
              <News />
            </Suspense>
          </Route> */}
          {/* <Route key="explore" exact path="/:locale/visiting">
            <Suspense fallback={<LoadingLogo />}>
              <ExploreVisiting />
            </Suspense>
          </Route> */}
          <Route path="/:locale?">
            <Suspense fallback={<LoadingLogo />}>
              <Explore />
            </Suspense>
          </Route>
        </AnimatedSwitch>
      </Router>
      <FetchData />
      <AppTitle title={t("AppName")} />
    </>
  );
}
